<template>
	<div class="main-content">
		<!--BreadCrumbs-->
		<ktv-breadcrumb
			:folder="$t('Loan', 'Loan')"
			:page="$t('Application', 'Application')"
			:title="$t('Loan Application List', 'Loan Application List')"
		/>
		<!--Tables Grid-->
		<TablesGrid />
	</div>
</template>
<script>
	import { KtvBreadcrumb } from "@/components"
	import TablesGrid from "./tables/TablesGrid"

	export default { 
		components: {
			KtvBreadcrumb, TablesGrid,
		},
	}
</script>
